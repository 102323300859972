import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

interface Announcement {
  id: number;
  title: string;
  subtitle: string;
  startDate: string;
  endDate: string;
  img: string;
}

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  @Input() modalData: Announcement = {} as Announcement;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  public environment: any = environment;

  constructor(public popUpModal: NgbActiveModal) {
  }

  emitShowAgain(event: any, announcementID: number) {
    const isChecked: boolean = event.target.checked;
    this.closeModal.emit({isChecked: isChecked, id: announcementID});
  }


  ngOnInit(): void {
    // Initialization logic if needed
  }
  // getImageUrl(img: string) {
  //   let newImg = img.replace(' ', '%20');
  //   return environment.appUrl + newImg;
  // }
}

